.header {
    position: fixed;
    padding: 2em var(--container-padding);
    width: 100%;
    z-index: 10;
}

.header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__item {
    opacity: 0;
}

.header__logo {
    flex: 1;
}

.header__logo img {
    width: 5em;
}

.header__nav {
    display: flex;
    gap: 0.889em;
}

.header__nav-item {
    font-size: 0.7778em;
    font-weight: 700;
    letter-spacing: -0.011em;
    text-decoration: none;
}

@media screen and (min-width: 992px) {
    .header__logo img {
        width: 7.75em;
    }
}
