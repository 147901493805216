.icon-link {
    --icon-circle-size: 1.5em;
    --icon-size: 0.5em;
    --icon-circle-border-color: rgba(255, 255, 255, 0.2);
    --icon-rotate: 0deg;
    text-decoration: none;
    font-weight: 600;
    color: var(--color-text, inherit);
}

.icon-link:hover {
    --icon-circle-border-color: rgba(255, 255, 255, 1);
    --icon-rotate: 45deg;
}

.icon-link__icon {
    width: var(--icon-circle-size);
    height: var(--icon-circle-size);
    margin-left: 0.5em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--icon-circle-border-color);
    border-radius: 100vmax;
    transition: 0.35s ease border;
}

.icon-link__icon svg {
    display: flex;
    width: var(--icon-size);
    height: var(--icon-size);
    transform: rotate(var(--icon-rotate));
    transition: 0.35s ease transform;
}
