.stories-block {
    counter-reset: slide;
    margin-bottom: 2em;
}

.stories-block__slider {
    overflow: visible !important;
}

.stories-block__slides {
    margin-bottom: 2em;
}

.stories-block__slide {
    counter-increment: slide;
}

.stories-block__control {
    --icon-circle-size: 3rem;
    --icon-size: 1rem;
    --icon-circle-border-color: rgba(255, 255, 255, 0.2);
    --icon-color: var(--color-text);
    width: var(--icon-circle-size);
    height: var(--icon-circle-size);
    margin-left: 0.5em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--icon-circle-border-color);
    border-radius: 100vmax;
    transition: 0.35s ease border;
    background: none;
}

.stories-block__control:hover {
    --icon-circle-border-color: rgba(255, 255, 255, 1);
}

.stories-block__control:disabled {
    --icon-color: rgba(255, 255, 255, 0.2);
    pointer-events: none;
}

.stories-block__control svg {
    display: flex;
    width: var(--icon-size);
    height: var(--icon-size);
    color: var(--icon-color);
    transition: 0.35s ease color;
}
