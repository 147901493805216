.footer {
    --font-weight: 700;
    --font-size: 0.6em;
    --text-spacing: 0.04em;
    text-align: center;
    padding: 3.5em 0;
    position: relative;
    z-index: 1;
}

.footer__inner {
    background-color: #ffffff;
    display: inline-flex;
    align-items: center;
    gap: 1em;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    padding: 0.25em 1.5em;
    border-radius: 100vmax;
}

.footer__nav {
    display: inline-flex;
    gap: inherit;
    align-items: center;
}

.footer__nav-item {
    opacity: 0.7;
    text-decoration: none;
    display: block;
    margin: 1em 0;
}

.footer__nav-item:hover {
    opacity: 1;
}
