.solutions-block {
    --grid-gap: 6em;
    margin-bottom: 6em;
}

.solutions-block__grid {
    display: flex;
    gap: var(--grid-gap);
    flex-wrap: wrap;
    flex-direction: column;
}

.solutions-block__item {
    flex: 1;
}

@media screen and (min-width: 992px) {
    .solutions-block {
        --grid-gap: 8em;
        margin-bottom: 8em;
    }
}
