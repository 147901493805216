.intro-text {
    --inner-max-width: 30rem;
    --line-opacity: 0;
    margin: 8rem 0;
}

.intro-text.animated {
    --line-opacity: 0.1;

}

.intro-text__inner {
    max-width: var(--inner-max-width);
}

.intro-text__block {
    text-wrap: balance;
    margin-bottom: 2em;
}

.intro-text__block p {
    margin: 0;
}

.intro-text__line {
    display: inline-block;
    opacity: var(--line-opacity);
}
