.snippet-feature--flip {
    --image-order: -1;
}

.snippet-feature__inner {
    display: flex;
    gap: 3em;
    align-items: center;
    flex-direction: column;
}

.snippet-feature__image {
    --image-ratio: 1/1;
    border-radius: 3.555em;
    overflow: hidden;
    width: 100%;
    max-width: 24rem;
    flex: 1;
}

.snippet-feature__body {
    flex: 1;
}

@media screen and (min-width: 992px) {

    .snippet-feature__inner {
        flex-direction: row;
        gap: 6em;
    }

    .snippet-feature__image {
        order: var(--image-order, 0);
    }
}
