html {
    display: block;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(211, 200, 200, 0);
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    font-synthesis: none;
    font-size: var(--step-0);
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font-family: var(--font-primary);
    line-height: 1.2;

    color: var(--color-text);
    background-color: var(--color-background);

    ::selection {
        color: var(--color-background);
        background-color: var(--color-text);
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }
}

body::after {
    content: "";
    background-image: linear-gradient(to right, gray 1px, transparent 1px);
    background-size: 6em;
    background-position: center center;
    display: block;
    width: 100%;
    height: 100vh;
    position: fixed;
    opacity: 0.1;
    pointer-events: none;
    top: 0;
}

a {
    color: inherit;
}

@media screen and (min-width: 992px) {
    body::after {
        background-size: 16em;
    }
}
