.footer-cta {
    --overlay-opacity: 0;

    position: relative;
    display: block;
    margin: 2em 0 2em 0;
    padding: 8em 2em;
    box-shadow: 0 0 0 1px var(--theme-teal-extra-light) inset;
    border-radius: 3.555rem;
    text-align: center;
    transition: 4s ease background-color;
    text-decoration: none;
}

.footer-cta.clicked {
    --overlay-opacity: 1;
}

.footer-cta:hover {
    background-color: var(--theme-teal-extra-light);
}

.footer-cta__overlay {
    position: absolute;
    inset: 1em;
    width: calc(100% - 2em);
    height: calc(100% - 2em);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-teal-light);
    color: var(--theme-white);
    font-weight: 600;
    border-radius: 3rem;
    z-index: 1;
    opacity: var(--overlay-opacity);
    pointer-events: none;
    transition: 0.35s ease opacity;
}

.footer-cta h2 {
    text-wrap: balance;
    margin-top: 0;
}

.footer-cta strong {
    font-weight: 600;
    transition: 0.2s ease all;
    opacity: 0.8;
    text-decoration: underline;
}

.footer-cta:hover strong {
    opacity: 1;
    text-decoration-color: transparent;
}

@media screen and (min-width: 992px) {
    .footer-cta {
        margin: 8em 0 2em 0;
        padding: 12em 6em;
        border-radius: 100vmax;
    }

    .footer-cta__overlay {
        border-radius: 100vmax;
    }

}
