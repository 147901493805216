
.snippet-story__counter:after {
    content: counter(slide, decimal-leading-zero);
    color: var(--theme-metal-light);
}

.snippet-story__body strong {
    font-weight: inherit;
    background-color: var(--theme-metal-light);
    padding: 0 0.25em;
}