.text-quote {
    --inner-max-width: 36rem;
}

.text-quote--right-align {
    --inner-margin-left: auto;
}

.text-quote__inner {
    max-width: var(--inner-max-width);
    padding: var(--section-inner-padding) 0;
    margin-left: var(--inner-margin-left, 0);
}

.text-quote__icon {
    width: 1.2em;
}

.text-quote__author {
    --text-spacing: 0;
    --font-weight: 600;
}
