.clients-block {
    --grid-gap: 3em;
    --inner-padding: 4em;
}

.clients-block__inner {
    display: flex;
    flex-wrap: wrap;
    gap: var(--grid-gap);
    justify-content: space-between;
    padding: var(--inner-padding) 0;
}

.clients-block__inner .clients-block__item:hover {
    --item-opacity: 1;
}

.clients-block__item {
    --item-opacity: 0.8;
    display: flex;
    justify-content: center;
    opacity: var(--item-opacity);
    transition: 0.35s ease opacity;
}
