/* @link https://utopia.fyi/type/calculator?c=320,18,1.25,1440,18,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

:root {
    --step--2: clamp(0.56rem, 0.5143rem + 0.2286vi, 0.72rem);
    --step--1: clamp(0.7rem, 0.6429rem + 0.2857vi, 0.9rem);
    --step-0: clamp(0.875rem, 0.8036rem + 0.3571vi, 1.125rem);
    --step-1: clamp(1.0938rem, 1.0045rem + 0.4464vi, 1.4063rem);
    --step-2: clamp(1.3672rem, 1.2556rem + 0.558vi, 1.7578rem);
    --step-3: clamp(1.709rem, 1.5695rem + 0.6975vi, 2.1973rem);
    --step-4: clamp(2.1362rem, 1.9618rem + 0.8719vi, 2.7466rem);
    --step-5: clamp(2.6703rem, 2.4523rem + 1.0899vi, 3.4332rem);

    --font-primary: "Manrope", sans-serif;
    --font-secondary: "Merriweather", serif;
}

.text {
    font-family: var(--font-family, var(--font-primary));
    font-optical-sizing: auto;
    font-style: normal;
    font-size: var(--font-size, inherit);
    font-weight: var(--font-weight, 500);
    color: var(--color-text);
    letter-spacing: var(--text-spacing, -0.02em);
}

.text--serif {
    --font-weight: 300;
    --font-family: var(--font-secondary);
}

.text--bold {
    --font-weight: 700;
}

.text--neg-2 {
    --font-size: var(--step--2);
}

.text--neg-1 {
    --font-size: var(--step--1);
}

.text--0 {
    --font-size: var(--step-0);
}

.text--1 {
    --font-size: var(--step-1);
}

.text--2 {
    --font-size: var(--step-2);
}

.text--3 {
    --font-size: var(--step-3);
}

.text--4 {
    --font-size: var(--step-4);
}

.text--5 {
    --font-size: var(--step-5);
}

.text--6 {
    --font-size: var(--step-6);
}