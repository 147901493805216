.image {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: var(--image-ratio, 16/9);
}

.image img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: var(--image-fit, cover);
}
