.button {
    padding: 0 2rem;
    min-height: 2.667rem;
    display: flex;
    align-items: center;
    border-radius: 100vmax;
    border: 1px solid var(--button-border-color, var(--border-color, transparent));
    background-color: var(--button-background, var(--color-background));
    color: var(--color-text);
    transition: 0.35s ease all;
    cursor: pointer;
}

.button.button--outlined:hover {
    --button-border-color: var(--color-text);
}

.button.button--filled:hover {
    --button-background: var(--theme-metal);
}