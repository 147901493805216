.text-image {
    --inner-max-width: 30rem;
    --background-size: max(50%, 36rem);
    --quote-background-color: rgba(255, 255, 255, 0);
    --color-text: var(--theme-white);
    position: absolute;
    bottom: 50%;
    right: var(--block-right, auto);
}

.text-image--right-align {
    --block-right: 0;
}

.text-image__inner {
    position: relative;
    max-width: var(--inner-max-width);
    margin-left: var(--inner-margin-left, 0);
}

.text-image__quote {
    z-index: 1;
    position: relative;
}

.text-image__quote:after {
    content: "";
    display: block;
    width: var(--background-size);
    height: var(--background-size);
    position: absolute;
    inset: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 100vmax;
    backdrop-filter: blur(17.5px);
    -webkit-backdrop-filter: blur(17.5px);
}

@media screen and (min-width: 992px) {
    .text-image {
        --background-size: max(50%, 48rem);
    }
}
