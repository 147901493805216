:root {
    --container-inner-width: 64em;
    --container-padding: 1.75em;
    --container-margin-large: 12em;
}

.section {
    --section-margin: 10vw;
    --section-margin-tight: 10vw;
    --section-inner-padding: 4em;
    --section-background-color: var(--color-background);
    --image-ratio: 2/3;

    position: relative;
    background-color: var(--section-background-color);
    overflow: hidden;
}

.section--tight {
    --section-margin: var(--section-margin-tight);
}

.section--no-background {
    --section-background-color: transparent;
}

.section__border {
    width: 100%;
    height: 1px;
    background-color: var(--color-border, inherit);
    display: block;
}

.section__inner {
    margin: 0 var(--section-margin);
    padding: var(--section-inner-padding) 0;
}

.section.section--sticky-content .section__inner {
    position: sticky;
    top: 0;
    z-index: 1;
}

.section__image {
    width: 100%;
    max-width: 96rem;
    margin: 0 auto;
    overflow: hidden;
    border-radius: var(--image-radius, 0);
}

.section__image--rounded {
    --image-radius: 1.667em;
}

.container {
    max-width: var(--container-inner-width);
    /* padding: 0 var(--container-padding); */
}

.container--left-align {
    margin-left: auto;
}

.image {
    width: 100%;
}

@media screen and (min-width: 992px) {
    :root {
        --container-padding: 2.5em;
    }

    .section {
        --section-margin: 18.5vw;
        --image-ratio: 16/9;
    }
    
    .section__image--rounded {
        --image-radius: 6.667em;
    }
}
