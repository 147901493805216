.text-quote {
    --inner-max-width: 36rem;
}

.text-quote__inner {
    max-width: var(--inner-max-width);
    padding: var(--section-inner-padding) 0;
    margin: auto;
    text-align: center;
}

.text-quote__icon {
    width: 100%;
    max-width: 1.2em;
    margin: 0 auto;
}

.text-quote__author {
    --text-spacing: 0;
    --font-weight: 600;
}
