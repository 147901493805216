.section-intro {
    --inner-max-width: 30rem;
    --inner-padding: 4rem;
}

.section-intro__inner {
    max-width: var(--inner-max-width);
    padding: var(--inner-padding) 0;
}

.section-intro__text {
    text-wrap: balance;
}

.section-intro__link a {
    text-decoration: none;
    font-size: var(--step--1);
}