:root {
    --theme-teal: #348794;
    --theme-teal-dark: #2c5a61;
    --theme-teal-light: #719196;
    --theme-teal-extra-light: #b7c8cb;
    --theme-metal: #2b3233;
    --theme-metal-light: #455052;
    --theme-metal-extra-light: #c4cdce;
    --theme-metal-extra-dark: #15191a;
    --theme-white: #dae4e5;

    --color-text: var(--theme-teal-dark);
    --color-background: var(--theme-white);
}

.theme--teal-dark {
    --color-text: #ffffff;
    --color-background: var(--theme-teal-dark);
}

.theme--metal {
    --color-text: var(--theme-white);
    --color-background: var(--theme-metal);
    --color-link: #ffffff;
    --color-border: var(--theme-metal-light);
}

.theme--metal-extra-dark {
    --color-text: var(--theme-white);
    --color-background: var(--theme-metal-extra-dark);
    --color-border: var(--theme-metal-light);
}

.theme--light {
    --border-color: var(--theme-teal-extra-light);
}
