.text-image {
    --inner-max-width: 30rem;
    --background-size: max(50%, 36rem);
    --quote-background-color: rgba(255, 255, 255, 0);
    --color-text: var(--theme-white);
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-image--right-align {
    --block-right: 0;
}

.text-image__inner {
    position: relative;
    max-width: var(--inner-max-width);
    margin-left: var(--inner-margin-left, 0);
}

.text-image__quote {
    z-index: 1;
    position: relative;
    text-align: center;
    padding: 0 3em;
}

.text-image__quote:after {
    content: "";
    display: block;
    width: var(--background-size);
    height: var(--background-size);
    position: absolute;
    inset: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 100vmax;
    backdrop-filter: blur(var(--blur-value, 18px));
    -webkit-backdrop-filter: blur(var(--blur-value, 18px));
}

@media screen and (min-width: 992px) {
    .text-image {
        --background-size: max(50%, 56rem);
    }

    .text-image__quote {
        padding: 0;
    }
    

}
