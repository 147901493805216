.clients-block {
    --grid-gap: 0em;
    --inner-padding: 4em;
}

.clients-block__inner {
    display: flex;
    flex-wrap: wrap;
    gap: var(--grid-gap);
    justify-content: space-between;
    align-items: center;
}

.clients-block__inner .clients-block__item:hover {
    --item-opacity: 1;
}

.clients-block__item {
    --item-opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    opacity: var(--item-opacity);
    transition: 0.35s ease opacity;
    width: 100%;
    max-width: 7rem;
}

.clients-block__text {
    --item-opacity: 1;
}

.clients-block__cta {
    flex-shrink: 0;
    position: relative;
}

.clients-block__cta a:before {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
}

.clients-block__plus {
    width: 1.75em;
}